<main [@fadeInOut] class="last-looks-container shadow-lg my-8">

  <div class="pagination-controls flex flex-row items-center">
    <span class="px-8">Page {{ currentPageIndex + 1 }} / {{ pages.length }}</span>
    <div class="ml-auto flex space-x-2">
      <button mat-raised-button color="warn" (click)="previousPage()" [disabled]="currentPageIndex === 0">Previous</button>
      <button mat-raised-button color="warn" (click)="nextPage()" [disabled]="currentPageIndex === pages.length - 1">Next</button>
    </div>
  </div>
  

  <div class="page-container">

    <app-last-looks-page 
      [page]="currentPage" 
      [canEditDocument]="editState"
      [resetSelectedLine] = "selectedLine"
      (pageUpdate) ="handlePageUpdate($event)"
      >
    </app-last-looks-page>
    
  </div>

</main>
  
