
<footer class="block bg-my-blue text-white">
  <div class="mx-auto w-full max-w-7xl px-5 py-4 md:px-10 md:py-24 lg:py-12">
    <div class="flex flex-row text-white items-start justify-between max-[767px]:flex-col max-[479px]:flex-col-reverse md:items-center">
      <div class="max-[991px]:flex-none">
        <p class="text-white max-[479px]:text-sm">© Copyright 2023. All rights reserved.</p>
      </div>
      <div class="max-[991px]: text-center font-semibold max-[991px]:py-1">
        <a href="https://tailspark.co/" class="inline-block py-2 pl-5 font-normal text-whitetransition hover:text-white max-[479px]:px-2.5 lg:pl-12">Made with Florwspark</a>
      </div>
    </div>
  </div>
</footer>
     