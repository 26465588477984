<div class="flex flex-col justify-center items-center w-full space-y-4">
    <form #callSheet name="callSheet" id="callSheet" class="w-full space-y-4">
      <input
        type="file"
        hidden
        #uploadButton
        enctype="multipart/form-data"
        accept="application/pdf"
        (change)="handleFileInput($event.target.files)"
      >
      <div class="flex flex-col space-y-2 w-full">
        <button
          *ngIf="!callsheet"
          mat-raised-button
          color="warn"
          class="w-full py-2 px-4 bg-red-600 text-white font-semibold rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          (click)="uploadButton.click()"
        >
         Add Callsheet
        </button>
        <button
          *ngIf="callsheet"
          mat-raised-button
          color="warn"
          class="w-full py-2 px-4 bg-red-600 text-white font-semibold rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          (click)="resetCallsheet()"
        >
          Remove Callsheet
        </button>
      </div>
    </form>
  </div>
  