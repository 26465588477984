export const LINE_TYPES = [
        'scene-header',
        'dialog',
        'character',
        'description',
        'first-description',
        'short-dialog',
        'parenthetical',
        'shot',
        'delete',
      ];