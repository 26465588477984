<div class='four-o-four center'>
  <div>
    <h1>PAGE NOT FOUND!</h1>
    <h3 class="spinner-text">Processing Script</h3>
  </div>
  <div class="four-o-four-image loading-image spin-image">
    <img class="spinning-bot" src="../../assets/icons/robotWhite.png">
  </div>
  <div class="four-o-four-footer">
    <h4>wha you looking for?</h4>
  </div>
</div>

