export const environment = {
  production: true,
  stripe:"pk_test_51IEIywBojwZRnVT4jdQQwACDdPb6Zy0ceGk09ZXvUWoeseNOakmMrGB5F9aVY73b0VQqwhZD6jCOE74GTGXbV4Tj00ggYYXpjQ",
  firebaseConfig:{
    apiKey: "AIzaSyBXD5kQfAS4lrmSJxYAuEUq8sxvXgWmCio",
    authDomain: "scriptthing.firebaseapp.com",
    databaseURL: "https://scriptthing.firebaseio.com",
    projectId: "scriptthing",
    storageBucket: "scriptthing.appspot.com",
    messagingSenderId: "195325163986",
    appId: "1:195325163986:web:e7a70646f087850353300b",
    measurementId: "G-XM3P84P6N7"
  },
  url: 'https://sides3.herokuapp.com',
  password:"NOTEWORTHY"
};



