<section class="tooltip-container flex justify-around w-full">
  <div class="w-full">
    <div class="shadow-xl bg-white w-full">
        <div class="flex justify-evenly w-full p-10">
          <button mat-raised-button color="warn" class="font-semibold" (click)="buttonAction.emit('resetDoc')">Reset Document</button>
          <button mat-raised-button color="warn" class="font-semibold" (click)="buttonAction.emit('stopEdit')">Stop Editing</button>
          <button mat-raised-button color="warn" class="font-semibold" (click)="buttonAction.emit('undo')">
            <span class="material-symbols-outlined">Undo </span>
          </button>
        </div>
      </div>
    </div>
</section>

