import { Component, OnInit } from '@angular/core';
import { SpinningBotComponent } from '../shared/spinning-bot/spinning-bot.component';

@Component({
  selector: 'app-four-ofour',
  templateUrl: './four-ofour.component.html',
  styleUrls: ['./four-ofour.component.css']
})
export class FourOfourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}