<section class="py-20 bg-white  width-80 m-top-8">
  <h2 class="font-bold text-center lg:text-6xl md:text-5xl">Stop Making Sides
     <span class="highlight">By Hand</span>
  </h2>
  <p class="font-light text-center text-4xl font-light-grey p-4 italic">Save Time in Three Easy Steps</p>
  <div class="flex flex-col justify-center md:width-[80vw] md:flex-row container lg:mx-auto shadow-lg border z-10 rounded-lg about-container">
    <!-- First Block -->
    <div class="flex flex-col justify-start mb-12 items-center">
      <div class="justify-self-center">
        <img src="assets/icons/bot-about-images/scan.jpg" alt="Scan Securely Image" class="w-full rounded-lg mb-4 lg:mb-0 justify-self-start">
      </div>
      <div class="lg:w-3/4  justify-self-center lg:order-1 text-center lg:text-left">
        <h3 class="lg:text-4xl text-3xl font-bold mb-4 text-dark-gray">Scan Securely</h3>
        <p class="md:text-[1rem] text-gray-700 mb-4 md:p-2">
          Your script is scanned, returned, and instantly deleted from our servers. We're backed by Google for added
          peace of mind. Nothing is saved — ever.
        </p>
      </div>
    </div>
  
    <!-- Second Block -->
    <div class="flex flex-col mb-12 items-center">
      <div class="justify-self-center">
        <img src="assets/icons/bot-about-images/select.jpg" alt="Select Scenes Image" class="w-full rounded-lg mb-4 lg:mb-0">
      </div>
      <div class="lg:w-3/4 lg:pl-8 text-center lg:text-left">
        <h3 class="text-4xl font-bold mb-4 text-dark-gray">Select Scenes</h3>
        <p class="md:text-[1rem] text-gray-700 mb-4 md:p-2">
          Select what you're shooting and we'll do the rest. Need a callsheet? Add one. Watermarks? No problem.
          Everything you need. Easy.
        </p>
      </div>
    </div>
  
    <!-- Third Block -->
    <div class="flex flex-col items-center">
      <div class="justify-self-center">
        <img src="assets/icons/bot-about-images/lastLooks.jpg" alt="Last Looks Image" class="w-full rounded-lg mb-4 lg:mb-0">
      </div>
      <div class="lg:w-3/4 lg:order-1 text-center lg:text-left justify-self-center">
        <h3 class="text-4xl font-bold mb-4 text-dark-gray">Last Looks</h3>
        <p class="md:text-[1rem] text-gray-700 mb-4 md:p-2">
          Preview your sides before payment. Secure in-browser editing for any last minute changes. Your download is
          encrypted. Everything deleted in 10 minutes - tops. And nothing is saved — ever.
        </p>
      </div>
    </div>
  </div>
  
</section>