<div class="flex flex-col justify-center items-center w-full space-y-4">
  <form class="space-y-4 w-full">
   
    <div>
      <button
        mat-raised-button
        color="warn"
        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        (click)="emitValue()"
      >
        Update Watermark
      </button>
    </div>
    <div>
      <label for="watermark" class="block text-sm font-medium text-gray-700"></label>
      <div class="mt-1">
        <input
          class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          type="text"
          id="watermark"
          name="watermark"
          [(ngModel)]="waterMark"
          [ngModelOptions]="{standalone: true}"
        >
      </div>
    </div>
  </form>
</div>
